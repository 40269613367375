import React, { useEffect } from "react";
import { useState } from "react";
import { Typography, Alert, Box, FormControlLabel, Radio, TextField, RadioGroup, Container, Button, CssBaseline, CircularProgress, Divider, Slide, Link as MuiLink} from "@mui/material"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { useSignup } from "../hooks/useSignup";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import Navbar from "../components/Navbar";


export default function SignupForm(props) {
    const { redirectLink } = props
    const { user } = useAuthContext();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [birth, setBirth] = useState(null);
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const {signup, error, isLoading} = useSignup();

    const parseDate = (day, month, year) => {
        return year + '-' + month + '-' + day
    }
    const [errorMessage, setErrorMessage] = useState("");
    const handleSubmit = async (e) => { 
        const birthdate = parseDate(day, month, year);
        e.preventDefault();
        await signup(email, password, first_name, last_name, gender, birthdate, user.email, redirectLink)
    }

    return (
        <>
            <CssBaseline/>
            {isLoading && 
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
               <CircularProgress/>
            </Box>}
            {!isLoading &&
            <Container maxWidth="md" sx={{height: "auto", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",}}>
                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", width: "90%", maxWidth: "400px",
                        gap: "15px", backgroundColor: "black", padding: "30px", borderRadius: "20px"}}>
                    {/* {error && <Alert sx={{color: "white", background: "#63020c"}} severity="error">{error}</Alert>} */}
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "20px",
                            right: "12px",
                            zIndex: "100",
                            maxWidth: "90%"
                        }}
                    >           
                        <Slide direction="left" in={error != null}>
                            <Alert severity="error">{error}</Alert>
                        </Slide>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column", 
                        gap: "5px",
                        alignItems: "center"
                    }}>
                        <Link to="/">
                            <img width="120px" className="logo-nav" src="partyapp.png"></img>
                        </Link>
                    </Box>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            First Name 
                        </Typography>
                        <TextField value={first_name} onChange={(e) => setFirstName(e.target.value)}
                            variant="outlined" placeholder="" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "40px", fontSize: "40px", width: "auto"}}
                        />
                    </Box>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            Last Name 
                        </Typography>
                        <TextField value={last_name} onChange={(e) => setLastName(e.target.value)}
                            variant="outlined" placeholder="" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "40px", fontSize: "40px", width: "auto"}}
                        />
                    </Box>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            Gender 
                        </Typography>
                        <RadioGroup row>
                            <FormControlLabel onChange={(e) => setGender(e.target.value)}
                            sx={{color: "#9CA3AF"}} value="Male" control={<Radio sx={{color: "white"}} />} label="Male" /> 
                            <FormControlLabel onChange={(e) => setGender(e.target.value)}
                            sx={{color: "#9CA3AF"}} value="Female" control={<Radio sx={{color: "white"}} />} label="Female" />
                        </RadioGroup>
                    </Box>
                    <Box sx={{width: '100%'}}>
                    <Box sx={{width: "auto", display: "flex", flexDirection: "column"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            Birthdate 
                        </Typography>
                        {/* <TextField variant="outlined" placeholder="" color="secondary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "40px", fontSize: "40px", width: "auto"}}
                        /> */}
                        {/*  */}
                        <Box 
                        sx={{
                            display: "flex",
                            gap: '3px'
                        }}
                        >
                            <TextField type="text" value={day} onChange={(e) => {
                                if (e.target.value > 31 || e.target.value < 0) {
                                    setDay("");
                                } else {
                                    setDay(e.target.value)
                                }
                            }}
                            variant="outlined" placeholder="DD" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "45px", fontSize: "40px", width: "auto"}}
                            />
                            <TextField type="text" value={month} onChange={(e) => {
                                if (e.target.value > 12 || e.target.value < 0) {
                                    setMonth("");
                                } else {
                                    setMonth(e.target.value);
                                }
                            }}
                            variant="outlined" placeholder="MM" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "45px", fontSize: "40px", width: "auto"}}
                            />
                            <TextField type="text" value={year} onChange={(e) => {
                                if (e.target.value > 2005 || e.target.value < 0) {
                                    setYear("");
                                } else {
                                    setYear(e.target.value);
                                }
                            }}
                            variant="outlined" placeholder="YYYY" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "45px", fontSize: "40px", width: "auto"}}
                            />
                        </Box>
                    </Box>
                    </Box>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            Email Address 
                        </Typography>
                        <TextField value={email} type="email" onChange={(e) => setEmail(e.target.value)}
                            variant="outlined" placeholder="your@email.com" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "40px", fontSize: "40px", width: "auto"}}
                        />
                    </Box>
                    <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Typography fontSize="16px" fontWeight="600" color="#9CA3AF">
                            Password
                        </Typography>
                        <TextField type="password" value={password} onChange={e => setPassword(e.target.value)}
                            variant="outlined" color="primary"
                            sx={{ input: {color: 'white', fontSize: "14px", padding: "12px"}, ":after": {},  
                            backgroundColor: "#1C1C1D", height: "40px", fontSize: "40px", width: "auto"}}
                        />
                    </Box>
                    <Button color="secondary"
                    onClick={(e) => {handleSubmit(e)}}
                    sx={{color: "black", width: "100%", borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "white",
                        ":hover": {backgroundColor: "#BEC3CB"}}}
                    >
                        <span>Sign Up</span>
                    </Button>
                    <Box sx={{display: "flex", gap: "6px"}}>
                        <Typography color="#D2D6DB" fontSize="14px">Already have an account?</Typography>
                        <Typography component={Link} to="/login" state={{ redirectLink }} color="white" fontSize="14px">Sign In</Typography>
                    </Box>
                    <Box sx={{display: "flex", gap: "6px"}}>
                        <Typography color="#D2D6DB" fontSize="14px" sx={{textDecoration: "none", textAlign: "center"}}>By signing up you agree to the <MuiLink sx={{color: "#D2D6DB", textDecorationColor: "#D2D6DB"}} href="/terms.pdf">Terms and Conditions</MuiLink> and to our <MuiLink sx={{color: "#D2D6DB", textDecorationColor: "#D2D6DB"}} href="/privacy.pdf">Privacy Policy</MuiLink></Typography>
                    </Box>
                    <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                    <a href="https://www.partyapp.io/organizers">
                        <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        >
                            <Typography color="white">powered by</Typography>
                            <Box component="img" src="partyapp.png" sx={{ width: "110px" }}/>
                        </Box>
                    </a>
                </Box>
            </Container>
            }
        </>
    )
}