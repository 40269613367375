import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useGuestCheckout = () => {
    const [error, setError] = useState(null);
    const { dispatch } = useAuthContext();

    const guestCheckout = async (email, firstName, lastName, token) => {
        setError(null);

        const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/cart/guest', {
            method: 'POST',
            body: JSON.stringify({ email, firstName, lastName}),
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        });
        const json = await response.json();

        if (!response.ok) {
            console.log(json.error)
            setError(json.error)
        }
        if (response.ok) {
            // Save the user to local storage
            localStorage.setItem('user', JSON.stringify(json));

            // update the auth context
            dispatch({type: 'LOGIN', payload: json});
        }
        return { response, json};
    }
    return { guestCheckout, error}
}